import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchToken, fetchMandats } from '../services/api';
import '../styles/MandatsList.css';
import {
  leftIcon,
  topImgMandatsList
} from '../common/Images';

const MandatsList = () => {
  const [mandats, setMandats] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [token, setToken] = useState('');
  
  const navigate = useNavigate();
  
  useEffect(() => {
    const getTokenAndMandats = async () => {
      try {
        const token = await fetchToken();
        setToken(token);
        const mandatsData = await fetchMandats(token);
        setMandats(mandatsData);
      } catch (error) {
        console.error('Erreur lors de la récupération des mandats:', error);
      }
    };

    getTokenAndMandats();
  }, []);

  const filteredMandats = mandats.filter((mandat) =>
    `${mandat.prenom} ${mandat.nom}`.toLowerCase().includes(searchTerm.toLowerCase())
  ).sort((a, b) =>
    `${a.prenom} ${a.nom}`.localeCompare(`${b.prenom} ${b.nom}`)
  );
  
  return (
    <div className="mandats-list">
      <div className="mandats-list-bloc1">
        <div className="mandats-list-bloc1-container">
          <h3>Représentants du groupe UIMM LYON-FRANCE</h3>
        </div>
        <img className="top-img" src={topImgMandatsList} alt="" />
        <button onClick={() => navigate(-1)} className="header-btn back-btn">
          <img src={leftIcon} alt="Back" />
        </button>
      </div>
      
      <div className="mandats-list-bloc2">
        <div className="mandats-list-bloc2-container">
          <div className="search-container">
            <input
              type="text"
              placeholder="Rechercher..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="search-button">
              <i className="fa fa-search"></i>
            </button>
          </div>
          <div className="mandats-list-table">
            {filteredMandats.map((mandat) => (
              <div key={mandat.id} className="mandat-item">
                <h4>{mandat.prenom} {mandat.nom}</h4>
                <p>{mandat.section}</p>
                <p>{mandat.entreprise}</p>
              </div>
            ))}
          </div>
          <p className="note">
            « Liste non exhaustive, de nombreux autres mandats sont exercés par des représentants de l'industrie
            dans les domaines de l'économie, du social, de l'emploi et de la formation »
          </p>
        </div>
      </div>
    </div>
  );
};

export default MandatsList;
