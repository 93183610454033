//import { useState } from 'react'
import '../styles/Header.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  homeIcon,
  leftIcon
} from '../common/Images';

function Header() {
	const location = useLocation();
	const navigate = useNavigate();

	const handleBackClick = () => {
		navigate(-1);
	};
	
	return (
		<header className="uimm-header">
			{location.pathname === '/networking' ? (
			  <button className="header-btn back-btn" onClick={handleBackClick}>
				<img src={leftIcon} alt="Back" />
			  </button>
			) : (
			  <Link className="header-btn home-btn" to="/">
				<img src={homeIcon} alt="Home" />
			  </Link>
			)}
		</header>
	)
}

export default Header
