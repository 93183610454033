// api.js
import axiosInstance from './axiosInstance';

export const fetchToken = async () => {
  const data = JSON.stringify({
    username: 'arxamaagency',
    password: 'CZ5tu@!RmTcZc#c)',
  });

  const config = {
    method: 'post',
    url: '/login',
    data: data,
  };

  const response = await axiosInstance(config);
  return response.data.token;
};

export const fetchParticipants = async (token) => {
  const config = {
    method: 'get',
    url: '/users',
    headers: { 
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axiosInstance(config);
  return response.data;
};

export const fetchMandats = async (token) => {
  const config = {
    method: 'get',
    url: '/mandats',
    headers: { 
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axiosInstance(config);
  return response.data;
};
