// axiosInstance.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://backend-uimm.arxama.com/api', // Set your base URL here
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;
