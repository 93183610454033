import '../styles/Program.css';
import { Link } from 'react-router-dom';
import {
  program,
  topImgProgram,
  rightIcon
} from '../common/Images';

function Program() {

	return (
		<div className="program">
			<div className="program-bloc1">
				<div className="program-bloc1-container">
					<h3>Au programme</h3>
				</div>
				<img className="top-img" src={topImgProgram} alt="" />
			</div>
			<div className="program-bloc2">
				<div className="program-bloc2-container">
					<img className="program" src={program} alt="Programme" />
					<Link className="btn btn-primary" to="/plan">
						<span>Découvrez le lieu</span>
						<img src={rightIcon} alt="" />
					</Link>
					<Link className="btn btn-secondary" to="/networking">
						<span>Commencez à réseauter !</span>
						<img src={rightIcon} alt="" />
					</Link>
				</div>
			</div>
		</div>
	)
}

export default Program
