import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/Details.css';
import {
  leftIcon
} from '../common/Images';

const Details = () => {
	
  const navigate = useNavigate();
  const { id } = useParams();
  
  return (
  
	<div className="details">
		<div className="details-bloc1">
			<div className="details-bloc1-container">
				{(id === "1") && <div><h3>Accueil</h3>
				</div>}
				{(id === "2") && <div><h3>Application</h3>
				<p>Point Relais</p></div>}
				{(id === "3") && <div><h3>WorldSkills</h3>
				<p>Salles Chrome & Nickel</p></div>}
				{(id === "4") && <div><h3>L’engagement communautaire</h3>
				<p>Agora Studio</p></div>}
				{(id === "5") && <div><h3>L’histoire de l’industrie et la créativité industrielle</h3>
				<p>Média Studio</p></div>}
				{(id === "6") && <div><h3>La promotion et l’attractivité des métiers de l’industrie</h3>
				<p>Studio</p></div>}
				{(id === "7") && <div><h3>Découverte des ateliers de l’institut des ressources industrielles</h3>
				<p>Atelier Productique Maintenance Mécanique</p></div>}
				{(id === "8") && <div><h3>Démonstration découpe laser </h3>
				<p>Atelier Chaudronnerie Tuyauterie Soudure</p></div>}
				{(id === "9") && <div><h3>L’avenir de l’industrie</h3>
				<p>Hall du bâtiment H</p></div>}
				{(id === "10") && <div><h3>Quels Hommes pour l’industrie de demain ?</h3>
				<p>Espace Robotique</p></div>}
			</div>
			<button onClick={() => navigate(-1)} className="header-btn back-btn"><img src={leftIcon} alt="Back" /></button>
		</div>
		
		<div className="details-bloc2">
			<div className="details-bloc2-container">
				{(id === "1") && <div>  
					<p></p>
				</div> }
				{(id === "2") && <div>  
					<p></p>
				</div> }
				{(id === "3") && <div> 
					<p>Plongeons dans l’univers de la compétition mondiale des métiers WorldSkills, véritable vitrine de la richesse des métiers et de l'excellence des compétiteurs, et mobilisons-nous pour la finale mondiale qui se tiendra à Lyon du 10 au 15 septembre 2024 !</p>
				</div> }
				{(id === "4") && <div>  
					<p>Découvrons des initiatives et des projets sociaux menés par des industriels pour soutenir leur communauté locale.</p>
				</div> }
				{(id === "5") && <div>  
					<p>Retraçons l’histoire et l’évolution d’UIMM, d’UIMM LYON-FRANCE et de l’institut des ressources industrielles et mettons en lumière des œuvres du cent-cinquantenaire, fruits de collaborations entre des artistes et des industriels.</p>
				</div> }
				{(id === "6") && <div>  
					<p>Découvrons les actions de promotion des secteurs et des métiers de l’industrie proposées en étroite collaboration avec la communauté industrielle locale (industriels, salariés) et l’écosystème du territoire (enseignants, professionnels de l’emploi et de l’insertion).</p>
				</div> }
				{(id === "7") && <div>  
					<p>Parcourons les ateliers de l’institut des ressources industrielles, où se mêlent technologie de pointe et savoir-faire artisanal, et découvrons les machines et les métiers auxquels les apprentis se forment.</p>
				</div> }
				{(id === "8") && <div>  
					<p>Assistons à une démonstration en direct d’un processus de découpe laser.</p>
				</div> }
				{(id === "9") && <div>  
					<p>Discutons des défis de l’industrie dans les années à venir, et voyons comment les industriels peuvent agir en faveur d’une industrie plus verte, plus engagée et plus performante.</p>
				</div> }
				{(id === "10") && <div>  
					<p>Échangeons autour du rôle du management pour faire face aux nouveaux enjeux auxquels l’industrie est confrontée.</p>
				</div> }
			</div>
		</div>
	</div>
  );
};

export default Details;
