import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Plan from './components/Plan';
import Mandats from './components/Mandats';
import MandatsList from './components/MandatsList';
import Participants from './components/Participants';
import Program from './components/Program';
import Networking from './components/Networking';
import Details from './components/Details';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './styles/Main.css';

const App = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [isIos, setIsIos] = useState(false);
  const [isInStandaloneMode, setIsInStandaloneMode] = useState(false);
  const [isChrome, setIsChrome] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipad|ipod/.test(userAgent);
    const isInStandaloneMode = window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
    const isChrome = /chrome/.test(userAgent) && /iphone|ipad|ipod/.test(userAgent);

    setIsIos(isIos);
    setIsInStandaloneMode(isInStandaloneMode);
    setIsChrome(isChrome);

    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallButton(true);
    };

    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('beforeinstallprompt', handler);
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setDeferredPrompt(null);
        setShowInstallButton(false);
      });
    }
  };

  return (
    <Router>
      <div>
        <Header />
        <main className="content">
          {showInstallButton && !isIos && (
            <button
              onClick={handleInstallClick}
              className="btn-install"
            >
              <i className="fa fa-download" style={{ fontSize: '24px' }}></i>
            </button>
          )}
          {isIos && !isInStandaloneMode && (
            <div className="ios-install-instructions">
              {isChrome ? (
                <p>Pour installer cette application sur votre iPhone avec Chrome, veuillez ouvrir Safari et naviguer jusqu'à cette page, puis appuyez sur le bouton de partage et sélectionnez "Ajouter à l'écran d'accueil".</p>
              ) : (
                <p>Pour installer cette application sur votre iPhone, appuyez sur le bouton de partage et sélectionnez "Ajouter à l'écran d'accueil".</p>
              )}
            </div>
          )}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/plan" element={<Plan />} />
            <Route path="/mandats" element={<Mandats />} />
			<Route path="/mandats-list" element={<MandatsList />} />
            <Route path="/participants" element={<Participants />} />
            <Route path="/program" element={<Program />} />
            <Route path="/networking" element={<Networking />} />
			<Route path="/details/:id" element={<Details />} />
			<Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
