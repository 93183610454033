import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Link } from 'react-router-dom';
import '../styles/Plan.css';
import { plan, moreIcon, upIcon, downIcon } from '../common/Images';

const Plan = () => {
  const handleScrollDown = () => {
    window.scrollBy({
      top: 300,
      left: 0,
      behavior: 'smooth'
    });
  };

  const handleScrollUp = () => {
    window.scrollBy({
      top: -300,
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="plan">
      <div className="plan-container">
        <button className="scroll-btn scroll-up" onClick={handleScrollUp}><img src={upIcon} alt="Up" /></button>
        <button className="scroll-btn scroll-down" onClick={handleScrollDown}><img src={downIcon} alt="Down" /></button>
        
        <TransformWrapper
          defaultScale={1}
          defaultPositionX={0}
          defaultPositionY={0}
          wheel={{ step: 0.05 }}
          doubleClick={{ step: 1.2 }}
          pinch={{ step: 2 }}
          panning={{ velocityDisabled: true }}
        >
          {({ zoomOut, resetTransform, setTransform }) => (
            <>
              <div className="tools">
                <button className="btn" onClick={() => {
                    setTransform(0, 0, 1);
                  }}>Réinitialiser</button>
              </div>
              <TransformComponent>
                <div className="map">
                  <img src={plan} alt="Plan" />
                  <Link to="/details/1" className="map-button" style={{ top: '52.5%', left: '67.7%', display: 'none' }}>
                    <img className="more-icon" src={moreIcon} alt="" />
                  </Link>
                  <Link to="/details/2" className="map-button" style={{ top: '50.5%', left: '44.3%', display: 'none' }}>
                    <img className="more-icon" src={moreIcon} alt="" />
                  </Link>
                  <Link to="/details/3" className="map-button" style={{ top: '45.2%', left: '75.5%' }}>
                    <img className="more-icon" src={moreIcon} alt="" />
                  </Link>
                  <Link to="/details/4" className="map-button" style={{ top: '41.75%', left: '66%' }}>
                    <img className="more-icon" src={moreIcon} alt="" />
                  </Link>
                  <Link to="/details/5" className="map-button" style={{ top: '36.2%', left: '84.5%' }}>
                    <img className="more-icon" src={moreIcon} alt="" />
                  </Link>
                  <Link to="/details/6" className="map-button" style={{ top: '38.1%', left: '66.4%' }}>
                    <img className="more-icon" src={moreIcon} alt="" />
                  </Link>
                  <Link to="/details/7" className="map-button" style={{ top: '47.95%', left: '21.7%' }}>
                    <img className="more-icon" src={moreIcon} alt="" />
                  </Link>
                  <Link to="/details/8" className="map-button" style={{ top: '65.6%', left: '18.1%' }}>
                    <img className="more-icon" src={moreIcon} alt="" />
                  </Link>
                  <Link to="/details/9" className="map-button" style={{ bottom: '13.5%', left: '20.5%' }}>
                    <img className="more-icon" src={moreIcon} alt="" />
                  </Link>
                  <Link to="/details/10" className="map-button" style={{ bottom: '3.8%', left: '21.5%' }}>
                    <img className="more-icon" src={moreIcon} alt="" />
                  </Link>
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </div>
    </div>
  );
};

export default Plan;
