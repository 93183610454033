import '../styles/Networking.css';
import {
  rightIcon,
  networkingIcon
} from '../common/Images';

function Networking() {

	return (
		<div className="networking">
			<div className="networking-bloc1">
				<div className="networking-bloc1-container">
					<img className="networking-icon" src={networkingIcon} alt="Networking" />
					<h1>Outil de networking</h1>
					<p>
						Vous allez être redirigé vers une autre application de chat, merci de cliquer de nouveau sur cette application pour continuer de naviguer dessus. 
					</p>
					<a className="btn" href="https://backend-uimm.arxama.com/chat" target="_blank" rel="noopener noreferrer">
						<span>Continuer</span>
						<img src={rightIcon} alt="" />
					</a>
				</div>
			</div>
		</div>
	)
}

export default Networking