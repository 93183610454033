import '../styles/Home.css';
import {
  home,
  topImgHome,
  rightImg,
  logo,
} from '../common/Images';

function Home() {
	
  return (
	<div className="home">
		<div className="home-bloc1">
			<div className="home-bloc1-container">
				<img className="logo" src={logo} alt="UIMM Logo" />
			</div>
			<img className="top-img" src={topImgHome} alt="" />
		</div>
		<div className="home-bloc2">
			<img className="right-img" src={rightImg} alt="" />
			<div className="home-bloc2-container">
				<h1>événement annuel de la communauté UIMM 
				<br/>Lyon-France</h1>
			</div>
		</div>
		<div className="home-bloc3">
			<img src={home} alt="" />
		</div>
	</div>
  );
}

export default Home;