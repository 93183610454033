import React, { useState } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import '@fortawesome/fontawesome-free/css/all.min.css';
import '../styles/Mandats.css';
import {
  rightIcon,
} from '../common/Images';


const Mandats = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  function openModal(content) {
    setModalContent(content);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="mandats">
      <div className="mandats-container">
		<h4>Mandats externes</h4>
        <div className="circle">
          <div className="segment top-left" onClick={() => openModal("Tribunal de commerce de Lyon<br>CCI : Chambre de Commerce et d'Industrie<br>ENE : Experts du Numérique en Entreprises<br>APORA : Association régionale des entreprises en environnement<br>SOMUDIMEC : Société Mutuelle de l'Union des Industries Métallurgiques, Électriques et Connexes de l'Isère<br>Rhône Dauphiné Développement<br>Comité de bassin<br>ADERLY : Agence pour le Développement Economique de la Région Lyonnaise<br>COFIL : Comité de Foire de Lyon<br>CARA European Cluster for Mobility Solutions<br>Nuclear Valley")}>
			  <div className="segment-container">
				<div className="content">ÉCONOMIE</div>
				<div className="plus-button">+</div>
			  </div>
          </div>
          <div className="segment top-right" onClick={() => openModal("Conseil de Prud'hommes de Lyon<br>Conseil de Prud'hommes de Villefranche<br>AST Grand Lyon<br>CPAM : Caisse Primaire d'Assurance Maladie<br>AGEMETRA<br>APICIL : Association de Prévoyance Interprofessionnelle des Cadres et Ingénieurs de la région Lyonnaise<br>CAF : Caisse d'Allocations Familiales<br>EBE : Entreprise à But d'Emploi<br>GSHH : Groupement Social Humaniste et pour l'Harmonie de la Société")}>
			  <div className="segment-container">
				<div className="content">SOCIAL</div>
				<div className="plus-button">+</div>
			  </div>
          </div>
          <div className="segment bottom-left" onClick={() => openModal("France Travail<br>CPIEF : Commission Paritaire Infrarégionale Emploi Formation<br>CPREFP : Commission Paritaire Régionale de l’Emploi et de la Formation Professionnelle")}>
			  <div className="segment-container">
				<div className="content">EMPLOI</div>
				<div className="plus-button">+</div>
			  </div>
          </div>
          <div className="segment bottom-right" onClick={() => openModal("ECAM LaSalle<br>INSA Lyon : Institut National des Sciences Appliquées<br>IUT (Institut Universitaire de Technologie) Lyon 1 <br>Polytech<br>École de production Gorge de Loup<br>École de production La Fabrique – Académie de mécanique<br>Fondation La Mache<br>GRETA (Groupement d'établissements publics locaux d'enseignement) CFA (Centre de Formation d'Apprentis) Lyon Métropole<br>GRETA (Groupement d'établissements publics locaux d'enseignement) CFA (Centre de Formation d'Apprentis) Rhône<br>Association nationale des pôles formation UIMM<br>Fondation Ilyse : Industrie Lyon Saint-Étienne")}>
			  <div className="segment-container">
				<div className="content">FORMATION</div>
				<div className="plus-button">+</div>
			  </div>
          </div>
          <div className="center" onClick={() => openModal("UIMM LYON-FRANCE<br>UIMM LYON CONSEIL<br>GEIQ Industrie Isère Rhône : Groupement d'Employeurs pour l'Insertion et la Qualification<br>AFPI / CFAI : Association de Formation Professionnelle de l'Industrie / Centre de formation d'Apprentis de l'Industrie<br>ITII : Instituts des Techniques d'Ingénieur de l'Industrie")}>
            <div className="content">Mandats au sein des instances du groupe UIMM LYON-FRANCE</div>
            <div className="plus-button">+</div>
          </div>
        </div>
        <Link to="/mandats-list" className="btn btn-primary" style={{marginTop: "30px", marginLeft: "0px", marginRight: "0px"}}>
			<span>Découvrez quelques mandataires</span>
			<img src={rightIcon} alt="" />
		</Link>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
        className="modal"
        overlayClassName="overlay"
		appElement={document.getElementById('root')}
      >
		<button onClick={closeModal}><i className="fa fa-close"></i></button>
		{parse(modalContent)}
      </Modal>
    </div>
  );
};

export default Mandats;
