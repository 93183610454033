import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { fetchToken, fetchParticipants } from '../services/api';
import '../styles/Participants.css';
import {
  rightIcon,
  topImgParticipants
} from '../common/Images';

const Participants = () => {
  const [participants, setParticipants] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [token, setToken] = useState('');
  
  useEffect(() => {
    const getTokenAndParticipants = async () => {
      try {
        const token = await fetchToken();
        setToken(token);
        const participantsData = await fetchParticipants(token);
        setParticipants(participantsData);
      } catch (error) {
        console.error('Erreur lors de la récupération des participants:', error);
      }
    };

    getTokenAndParticipants();
  }, []);

  const filteredParticipants = participants.filter((participant) =>
    `${participant.prenom} ${participant.nom}`.toLowerCase().includes(searchTerm.toLowerCase())
  ).sort((a, b) =>
    `${a.prenom} ${a.nom}`.localeCompare(`${b.prenom} ${b.nom}`)
  );

  return (
    <div className="participants">
	  <div className="participants-bloc1">
		<div className="participants-bloc1-container">
			<h3>Liste des participants</h3>
		</div>
		<img className="top-img" src={topImgParticipants} alt="" />
	  </div>
	  
	  <div className="participants-bloc2">
		<div className="participants-bloc2-title-container">
			<Link className="btn btn-secondary" to="/networking">
				<span>Échangez avec les participants !</span>
				<img src={rightIcon} alt="" />
			</Link>
		</div>
	  	<div className="participants-bloc2-container">
		  
		  <div className="search-container">
			<input
			  type="text"
			  placeholder="Rechercher..."
			  value={searchTerm}
			  onChange={(e) => setSearchTerm(e.target.value)}
			/>
			<button className="search-button"><i className="fa fa-search"></i></button>
		  </div>
		  <div className="participants-table">
			{filteredParticipants.map((participant) => (
			  <div key={participant.id} className="participant-item">
				<h4>{participant.prenom} {participant.nom}</h4>
				<p>{participant.titre}</p>
				<p>{participant.entreprise}</p>
			  </div>
			))}
		  </div>
		</div>
	  </div>
    </div>
  );
};

export default Participants;