import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';
import {
  programIcon,
  mapIcon,
  userIcon,
  listIcon,
  chatIcon,
  upIcon,
  downIcon,
  programIcon2,
  mapIcon2,
  listIcon2,
  userIcon2,
  chatIcon2,
  outIcon
} from '../common/Images';

function Footer() {
  const [isWindowVisible, setIsWindowVisible] = useState(false);

  const toggleWindow = () => {
    setIsWindowVisible(!isWindowVisible);
  };

  return (
    <footer className='uimm-footer'>
      <div className="footer-container">
	    {!isWindowVisible && (<div className="footer-icon-ud footer-up" onClick={toggleWindow}>
          <img src={upIcon} alt="Up" />
        </div>)}
		{isWindowVisible && (<div className="footer-icon-ud footer-down" onClick={toggleWindow}>
          <img src={downIcon} alt="Down" />
        </div>)}
        <ul className="footer-nav">
          <li>
            <Link to="/program">
              <img src={programIcon} alt="Programme" />
            </Link>
          </li>
          <li>
            <Link to="/plan">
              <img src={mapIcon} alt="Plan interactif" />
            </Link>
          </li>
          <li>
            <Link to="/mandats">
              <img src={userIcon} alt="Nos mandats" />
            </Link>
          </li>
          <li>
            <Link to="/participants">
              <img src={listIcon} alt="Liste des participants" />
            </Link>
          </li>
          <li>
            <Link to="/networking">
              <img src={chatIcon} alt="Outil de networking" />
            </Link>
          </li>
        </ul>
      </div>
      {isWindowVisible && (
        <div className="popup-window">
          <ul>
            <li>
              <Link to="/program">
                <img src={programIcon2} alt="Programme" />
                <span>Programme</span>
              </Link>
            </li>
            <li>
              <Link to="/plan">
                <img src={mapIcon2} alt="Plan interactif" />
                <span>Plan interactif</span>
              </Link>
            </li>
            <li>
              <Link to="/mandats">
                <img src={userIcon2} alt="Nos mandats" />
                <span>Nos mandats</span>
              </Link>
            </li>
            <li>
              <Link to="/participants">
                <img src={listIcon2} alt="Liste des participants" />
                <span>Liste des participants</span>
              </Link>
            </li>
            <li>
              <Link to="/networking">
                <img src={chatIcon2} alt="Outil de networking" />
                <span>Outil de networking</span>
				<img className="out-icon" src={outIcon} alt="" />
              </Link>
            </li>
          </ul>
        </div>
      )}
    </footer>
  );
}

export default Footer;
